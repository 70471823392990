import React from "react"
import * as styles from "../styles/footer.module.scss"
import metaLogo from "../../static/meta-partner.png"
import snapLogo from "../../static/snap-partner.png"
import { Link } from "gatsby"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Footer = ({lang}) => {
  if (cookies.get('opt-out') ){
    window[`ga-disable-G-HDS29BJKZZ`] = true
  }
  
  return (
    <footer className={styles.footer}>
        <div className={styles.fourColumns}>
          <div>
            the sun project
          </div>
          <div>
            <img src={metaLogo} alt="meta partner" width="382" height="358" />
            <img src={snapLogo} alt="snap partner" width="200" height="255"/>
          </div>
          <div>
            <a href="https://www.facebook.com/thesunproject92/" title="facebook" target="blank">facebook</a><br />
            <a href="https://www.instagram.com/_thesunproject/" title="instagram" target="blank">instagram</a><br />
            <a href="https://www.linkedin.com/company/18992712/" title="linkedin" target="blank">linkedin</a><br />
            <a href="https://www.tiktok.com/@_thesunproject" title="tiktok" target="blank">tiktok</a>
          </div>
          <div>
            {lang.slice(0, 2) === "fr" &&
              <Link to={"/fr/mentions-legales"}>
                mentions légales<br /> et politique de confidentialité
              </Link>
            }
            {lang.slice(0, 2) === "en" &&
              <Link to={"/en/legal-notice"}>
                legal notice <br /> and privacy policy
               </Link>
            }
          </div>
        </div>
        <div className={styles.twoColumns}>
          <div>
            © {new Date().getFullYear()}, the sun project, immersive experiences social studio
          </div>
          <div>
            {lang.slice(0, 2) === "fr" ? "tous droits réservés" : "all rights reserved"}
          </div>
          
        </div>

        
    </footer>
  )
}

export default Footer
