import "./src/styles/global.scss"
import * as React from 'react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import { linkResolver } from './linkResolver.js'
import PageTemplate from './src/components/Layout'
//import { Helmet } from "react-helmet"

export const wrapRootElement = ({ element }) => (
  <>
  {/* <Helmet>
      <html lang="fr" prefix="og: https://ogp.me/ns#" />
      <body />
    </Helmet> */}
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>

  </>
)

// export const onPreRenderHTML = ({ getHeadComponents, replaceHeadComponents }) => {
//   const headComponents = getHeadComponents()
//   headComponents.sort((x, y) => {
//     if (x.props && x.props["data-gatsby-head"]) {
//       return -1
//     } else if (y.props && y.props["data-gatsby-head"]) {
//       return 1
//     }
//     return 0
//   })
//   replaceHeadComponents(headComponents)
// }