// extracted by mini-css-extract-plugin
export var canvasClass = "Drawing-module--canvasClass--a5a93";
export var duplicateCanvasClass = "Drawing-module--duplicateCanvasClass--c9676";
export var eraserCursor = "Drawing-module--eraserCursor--cfcd7";
export var eraserPath = "Drawing-module--eraserPath--32601";
export var hiddenEraser = "Drawing-module--hiddenEraser--8e96f";
export var hiddenPen = "Drawing-module--hiddenPen--e3bc8";
export var hideCanvas = "Drawing-module--hideCanvas--bae15";
export var penCursor = "Drawing-module--penCursor--70f90";
export var toolEraser = "Drawing-module--toolEraser--ca587";
export var toolPen = "Drawing-module--toolPen--24ab7";
export var toolSelector = "Drawing-module--toolSelector--b0c86";
export var tools = "Drawing-module--tools--572cf";