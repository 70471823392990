import React, {useEffect, useState, useRef, useLayoutEffect} from "react"
import { Link} from 'gatsby'
import { LanguageSwitcher } from '../components/LanguageSwitcher'
import {useScroll} from '../hooks/useScroll'
import * as styles from "../styles/menu.module.scss"

const Menu = ({lang, altLangs, type}) => {

  lang = lang.slice(0, 2)
  
  let urls
  if (lang === "fr"){
    urls = {
      agency : "agence",
      drawing: "dessin",
      games: "jeux"
    }
  } else {
    urls = {
      agency : "agency",
      drawing: "drawing",
      games: "games"
    }
  }
  
  const scroll = useScroll()

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  const updateHeight = () => {
    setHeight(ref.current.clientHeight);
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  useLayoutEffect(() => {
    updateHeight()
  }, []);

  const menuFixed = scroll.direction === "down" ? (scroll.y > height ? true : false) : (scroll.y > 0 ? true : false)
  
  const [menuOpened, setMenuOpened] = useState(false)  
  const [menuMobileOpened, setMenuMobileOpened] = useState(false)

  useEffect(() => {
    if (scroll.y < height) return
    if (scroll.direction === "up") {
      setMenuOpened(true)
    } else {
      setMenuOpened(false)
      setMenuMobileOpened(false)
    }
  }, [scroll, height])

  return (
    <header className={(menuFixed || menuMobileOpened ) ? styles.headerFixed : styles.header}>
        <div ref={ref} className={`${styles.menu} ${menuFixed? styles.menuFixed : ""} ${menuMobileOpened? styles.menuMobileActive : ""} ${menuOpened ? styles.menuFixedActive : ""}`}>
        <Link to={`/${lang}`}>the sun project</Link>
          <ul className={styles.subMenu}>
              <li><Link to={`/${lang}/creations`}> {lang === "fr" ? "créations" : "creations" }</Link></li>
              <li><Link to={`/${lang}/experiences`}> {lang === "fr" ? "expériences" : "experiences" }</Link></li>
              <li><Link to={`/${lang}/${urls.agency}`}>{lang === "fr" ? "agence" : "agency" }</Link></li>
              <li><Link to={`/${lang}/news`}>news</Link></li>
              <li><Link to={`/${lang}/contact`}>contact</Link></li>
              <li><LanguageSwitcher lang={lang} altLangs={altLangs} /></li>
          </ul>
        </div>
        <div className={`${styles.tools} ${menuFixed? styles.toolsFixed : ""} ${menuMobileOpened? styles.toolsMobileActive : ""} ${menuOpened ? styles.toolsFixedActive : ""}`}>
              <Link to={`/${lang}/${urls.drawing}`} activeClassName={styles.active} aria-label="Go to the drawing page">
                <svg className={`${styles.toolsIcon} ${styles.icon1}`} viewBox="0 0 85.7 148.4" >
                  <path className="st0" d="M50.2,7.6c0-0.7-2.3-1.3-5.1-1.3c-2.8,0-5.1,0.6-5.1,1.3S42.3,9,45.1,9C47.9,9,50.2,8.4,50.2,7.6z"/>
                  <path className="st0" d="M65.9,7.3c-0.8-5.9-17.8-6.2-21.3-6.2S24.2,1.5,23.4,7.3h0v103.5c0,0.8,0.1,2.1,4.1,3.1v9.9l6.8,4.6V139h4v7.9
                    l12.4-1.7V139h4.1v-10.6l6.7-4.5V114c4.3-1,4.4-2.3,4.4-3.1L65.9,7.3L65.9,7.3L65.9,7.3z M61.9,95.4c-1.7,0.7-7.6,1.7-17.3,1.7
                    s-15.6-1-17.3-1.7V12.1c5.5,2.2,14.8,2.4,17.3,2.4s11.8-0.2,17.3-2.4C61.9,12.1,61.9,95.4,61.9,95.4z M44.6,5.1
                    c9.5,0,15.3,1.6,17,2.7c-1.7,1.1-7.5,2.7-17,2.7s-15.3-1.6-17-2.7C29.3,6.7,35.2,5.1,44.6,5.1z M38.3,135v-5.4
                    c2.3,0.2,5.1,0.2,6.2,0.2c2,0,4.5-0.1,6.3-0.2v5.4L38.3,135L38.3,135z M57.5,121.7l-5.5,3.7c-0.9,0.1-3.5,0.3-7.5,0.3
                    s-6.7-0.2-7.5-0.3l-5.6-3.8v-7.2c3.1,0.3,7.3,0.6,13.2,0.6s9.8-0.2,12.9-0.5L57.5,121.7L57.5,121.7z M44.6,111.1
                    c-9.4,0-15.3-0.8-17.3-1.4V99.6c1,0.3,2.1,0.5,3.4,0.7c3.7,0.6,8.7,0.9,13.9,0.9s10.2-0.3,14-0.9c1.2-0.2,2.4-0.4,3.3-0.7v10.2
                    C60,110.3,54,111.1,44.6,111.1z"/>
                  <rect x="53.8" y="16.4" className="st0" width="4.4" height="75.7"/>
                </svg>
              </Link>

            {type === "drawing" &&
                <Link to={`/${lang}`} aria-label="Close and go back to homepage">
                  <svg className={`${styles.toolsIcon} ${styles.icon1}`} viewBox="0 0 85.7 148.4">
                    <path className="st0" d="M43.5,104.4c-17.1,0-31-13.9-31-31s13.9-31,31-31s31,13.9,31,31S60.6,104.4,43.5,104.4z M43.5,46.3
                      c-14.9,0-27,12.1-27,27c0,14.9,12.1,27,27,27s27-12.1,27-27C70.5,58.4,58.4,46.3,43.5,46.3z"/>
                    <rect x="41.5" y="53.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -39.1117 52.2086)" className="st0" width="4" height="38.8"/>
                    <rect x="24.1" y="71.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -39.1119 52.2093)" className="st0" width="38.8" height="4"/>
                  </svg>
                </Link>
            }

              <Link to={`/${lang}/${urls.games}`} activeClassName={styles.active} aria-label="Go to the games page" >
                {/* <svg className={`${styles.toolsIcon} ${styles.iconReverse}`} viewBox="0 0 85.72 104.12">
                  <path d="M48.87,102.97c-18.22,0-30.47-7.16-30.47-17.82,0-2.85,.83-5.79,2.38-8.67-12.23-5.11-17.04-14.22-17.04-22.94,0-3.9,.92-7.69,2.69-11.28-3.61-3.42-5.84-7.84-5.84-13.31C.6,11.14,23.79,3.22,37.5,1.35l.54,3.96C21.93,7.51,4.6,15.84,4.6,28.96c0,3.89,1.47,7.13,3.93,9.75,2.97-4.34,7.28-8.3,12.79-11.66,9.72-5.93,22.34-9.47,33.76-9.47,13.55,0,28.65,5.36,28.65,13.05,0,6.88-6.29,12.53-18.2,16.35-10.37,3.32-22.55,4.5-30.5,4.5-9.15,0-18.68-2.14-25.39-6.66-1.22,2.71-1.89,5.62-1.89,8.73,0,8.86,5.54,15.63,15.28,19.47,7.33-9.73,22.67-17.92,40.92-17.92,13.55,0,15.02,6.5,15.02,9.29,0,7.49-14.84,16.2-33.98,16.2-8.12,0-14.84-1-20.33-2.7-1.47,2.53-2.25,5.03-2.25,7.28,0,8.13,10.88,13.82,26.47,13.82v4Zm-21.75-28.59c5.11,1.44,11.11,2.2,17.87,2.2,18.65,0,29.98-8.48,29.98-12.2,0-3.36-4.01-5.29-11.02-5.29-17.08,0-30.22,7.33-36.83,15.29ZM11.57,41.32c5.96,4.18,14.9,6.15,23.46,6.15,7.64,0,19.35-1.13,29.28-4.31,5.76-1.84,15.42-5.92,15.42-12.54,0-3.56-10.58-9.05-24.65-9.05-15.29,0-35.25,7.28-43.51,19.74Z"/>
                </svg> */}
                  <svg className={`${styles.toolsIcon} ${styles.iconReverse} ${styles.icon2}`} viewBox="0 0 85.7 104.1">
                    <polygon className="st0" points="43.6,95 8.4,85.1 8.3,50.3 12.3,50.3 12.4,82.1 43.4,90.8 72.6,80 72.8,48.9 76.8,48.9 76.6,82.8 "/>
                    <path className="st0" d="M43.7,61.7L5.2,51.2L5.1,35.1l38.4,10.1l36.7-11.9L80,49.6L43.7,61.7z M9.2,48.2l34.4,9.4L76,46.7l0.1-7.8
                      L43.6,49.4L9.1,40.3L9.2,48.2z"/>
                    <rect x="41.7" y="47" className="st0" width="4" height="45.6"/>
                    <polygon className="st0" points="6.2,39 5.1,35.1 43.7,24 80.1,33.4 79.2,37.3 43.8,28.2 "/>
                    <polygon className="st0" points="59.2,85.6 59.1,42.2 27.6,32.7 28.7,28.9 63.1,39.2 63.2,85.6 "/>
                    <polygon className="st0" points="27.1,87.9 23.1,87.9 23.2,41.5 58.9,28.1 60.3,31.9 27.2,44.3 "/>
                    <g>
                      <path className="st0" d="M43.6,37.2c-15.4-2.7-31.1-6.9-31.1-17.4C12.5,9,19.3,7.4,23.3,7.4c8.1,0,12.6,8.8,16.2,15.9
                        c2,3.9,4.5,8.8,6.3,8.8c2.4,0,4.2-5.6,5.8-10.6c2.5-7.8,5.3-16.6,13.1-16.6c5.7,0,12.4,3.2,12.4,12c0,4.6-2.8,8.6-8.3,11.8l-2-3.5
                        c4.2-2.5,6.3-5.2,6.3-8.3c0-7.2-5.9-8-8.4-8c-4.9,0-7.1,7-9.3,13.8c-2.2,6.9-4.3,13.4-9.6,13.4c-0.7,0-1.4-0.1-2-0.4L43.6,37.2z
                        M23.3,11.4c-2.9,0-6.8,0.9-6.8,8.4c0,2,0,7.9,23.7,12.7c-1.5-2-2.8-4.6-4.2-7.4C32.7,18.7,28.9,11.4,23.3,11.4z"/>
                      <path className="st0" d="M48.1,36l-1-3.9l0.4-0.1c6.2-1.6,14-3.7,19.3-6.8l2,3.5C63,32.1,55,34.2,48.5,35.9L48.1,36z"/>
                      <path className="st1" d="M50.4,34.4"/>
                    </g>
                  </svg>

              </Link>

            {type === "game" &&
                <Link to={`/${lang}`} >
                   <svg className={`${styles.toolsIcon} ${styles.icon2}`} viewBox="0 0 85.7 104.1">
                    <path className="st0" d="M43.5,83.4c-17.1,0-31-13.9-31-31s13.9-31,31-31s31,13.9,31,31S60.6,83.4,43.5,83.4z M43.5,25.3
                      c-14.9,0-27,12.1-27,27s12.1,27,27,27s27-12.1,27-27S58.4,25.3,43.5,25.3z"/>
                    <rect x="41.5" y="32.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -24.2334 46.0558)" className="st0" width="4" height="38.8"/>
                    <rect x="24.1" y="50.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -24.2339 46.0559)" className="st0" width="38.8" height="4"/>
                  </svg> 
                </Link>
            }

        </div>
        <div className={`${styles.menuOpener} ${menuFixed? styles.menuOpenerFixed : ""} ${menuFixed && menuOpened? styles.menuOpenerActive : ""} ${menuMobileOpened ? styles.menuOpenerActiveMobile : ""}`} onClick={() => setMenuMobileOpened(!menuMobileOpened)}>
          <div></div>
          <div></div>
          <div></div>
        </div>
    </header>
  )
}

export default Menu
