// extracted by mini-css-extract-plugin
export var active = "menu-module--active--05d74";
export var header = "menu-module--header--218d9";
export var headerFixed = "menu-module--header-fixed--50803";
export var icon1 = "menu-module--icon1--abb31";
export var icon2 = "menu-module--icon2--f5d82";
export var iconReverse = "menu-module--icon-reverse--4c8a2";
export var languageSwitcher = "menu-module--language-switcher--39f82";
export var menu = "menu-module--menu--3ee54";
export var menuFixed = "menu-module--menu-fixed--d89c6";
export var menuFixedActive = "menu-module--menu-fixed-active--a2bb3";
export var menuMobileActive = "menu-module--menu-mobile-active--16ab6";
export var menuOpener = "menu-module--menu-opener--28533";
export var menuOpenerActive = "menu-module--menu-opener-active--dc293";
export var menuOpenerActiveMobile = "menu-module--menu-opener-active-mobile--112b5";
export var menuOpenerFixed = "menu-module--menu-opener-fixed--f8194";
export var subMenu = "menu-module--sub-menu--11fbb";
export var tools = "menu-module--tools--39268";
export var toolsFixed = "menu-module--tools-fixed--d2e0c";
export var toolsIcon = "menu-module--tools-icon--b1bf2";
export var toolsMobileActive = "menu-module--tools-mobile-active--9249f";