module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"thesunproject","accessToken":"MC5ZelFxZVJFQUFGUkVvRjhi.E--_vV3vv73vv73vv70LIO-_ve-_vQvvv73vv73vv73vv73vv73vv73vv73vv70Gfu-_ve-_vSHvv71S77-9JBQiPFg","customTypesApiToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6InRoZXN1bnByb2plY3QtZjZkMmE3OWItOGNkMS00MWE0LWJiMjQtNTM3OGQ2NmQxZjI2XzQiLCJkYXRlIjoxNjY0MzYzMTUxLCJkb21haW4iOiJ0aGVzdW5wcm9qZWN0IiwiaWF0IjoxNjY0MzYzMTUxfQ.4lZsS-JVc4HniLssyCDW5Ayqm93z4xfXtnOo86kb1dk","promptForAccessToken":true,"apiEndpoint":"https://thesunproject.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-HDS29BJKZZ","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"7242048","respectDNT":true,"productionOnly":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
