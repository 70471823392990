import * as React from 'react'
import { Link } from "gatsby"
import { linkResolver } from '../../linkResolver'

export const LanguageSwitcher = ({ lang, altLangs }) => {
  if (!altLangs) return
 // Render all the alternate language options
  const alternateLangOptions = altLangs.map(
    (altLang, index) => (
        <Link key={index} to={linkResolver(altLang)}> {altLang.lang.slice(0, 2)} </Link>
    ),
  )

  return (
    <>
        {alternateLangOptions}
    </>
  )
}