import React, {useState } from "react"
import PropTypes from "prop-types"
import Menu from "./Menu"
import Footer from "./Footer"
import Background from "./Background"
import RgpdBanner from "./RgpdBanner"
import { Helmet } from "react-helmet"

const Layout = ({ children, page, type, originDrawpage}) => {
  
  function getStorageValue(key) {
    if (typeof window == "undefined") return
    return sessionStorage.getItem(key)
  }

  function pickColor(){
    if (Math.floor(Math.random() * 2) === 0) return "pink"
    else return "blue"
  }

  let noRedraw
  if (typeof window !== "undefined") {
    noRedraw = getStorageValue("noRedraw")
  }

  const [isIntro, setIsIntro] = useState(noRedraw ? false : true)

  let color = getStorageValue("color")
  if (!color) {
    color = pickColor()
    if (typeof window == "undefined") return
    sessionStorage.setItem("color", color);
  }

  // Navigation
  if (typeof window !== `undefined`){
    sessionStorage.setItem("previous_hub", "")
  }

  return (
    <>
      {/* <Helmet>
        <title>{title()}</title>
        <meta name="description" content={page.type !== "drawing" ? page.data.seo_description.text : "" } />
        <meta name="keywords" content={page.type !== "drawing" ? page.data.seo_keywords.text : "" } />
        <meta name="author" content="the sun project" />
        <meta property="og:title" content={title()} />
        <meta property="og:site_name" content="the sun project" />
        <meta property="og:description" content={page.type !== "drawing" ? page.data.seo_description.text : "" } />
        <meta property="og:image" content={image()} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={page.lang} />
        <link rel="icon" type="image/png" href={favicon} />
        <html lang={page.lang} className={`${type === "hub" ? "no-mobile-scroll" : ""} ${isIntro ? "no-scroll" : ""}`} prefix="og: https://ogp.me/ns#" />
        <body className={isIntro ? "content-hided": "content-visible"} />
      </Helmet> */}
      <Helmet>
        <html lang={page.lang} className={`${type === "hub" ? "no-mobile-scroll" : ""} ${isIntro ? "no-scroll" : ""}`} prefix="og: https://ogp.me/ns#" />
        <body className={isIntro ? "content-hided": "content-visible"} />
      </Helmet>
      <Menu lang={page.lang} altLangs={page.alternate_languages} type={page.type}/>
      {children}
      <Background color={color} type={type} originDrawpage={originDrawpage} setIsIntro={setIsIntro}/>
      
      <RgpdBanner lang={page.lang} />
      <Footer lang={page.lang} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
