import {useEffect, useLayoutEffect, useState} from "react"


const getWidth = (element) => element.current.getBoundingClientRect().width
const getHeight = (element) => element.current.getBoundingClientRect().height


export const useBoundingRect = (element) => {
    const [boundingRect, setBoundingRect] = useState({
        width: 0,
        height: 0
    })

    useLayoutEffect(() => {
        setBoundingRect({
            width: getWidth(element),
            height: getHeight(element),
        })
    }, []);

    useLayoutEffect( () => {
        let timeoutId = null
        const resizeListener = () => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(() => setBoundingRect({
                width: getWidth(element),
                height: getHeight(element),
            }), 250)
        }
        window.addEventListener("resize", resizeListener)
        return () => window.removeEventListener("resize", resizeListener)
    }, [])
    return boundingRect
}