exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-creation-js": () => import("./../../../src/templates/creation.js" /* webpackChunkName: "component---src-templates-creation-js" */),
  "component---src-templates-creationshub-js": () => import("./../../../src/templates/creationshub.js" /* webpackChunkName: "component---src-templates-creationshub-js" */),
  "component---src-templates-drawing-js": () => import("./../../../src/templates/drawing.js" /* webpackChunkName: "component---src-templates-drawing-js" */),
  "component---src-templates-filtershub-js": () => import("./../../../src/templates/filtershub.js" /* webpackChunkName: "component---src-templates-filtershub-js" */),
  "component---src-templates-foohshub-js": () => import("./../../../src/templates/foohshub.js" /* webpackChunkName: "component---src-templates-foohshub-js" */),
  "component---src-templates-games-js": () => import("./../../../src/templates/games.js" /* webpackChunkName: "component---src-templates-games-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-newshub-js": () => import("./../../../src/templates/newshub.js" /* webpackChunkName: "component---src-templates-newshub-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-tagshub-js": () => import("./../../../src/templates/tagshub.js" /* webpackChunkName: "component---src-templates-tagshub-js" */)
}

