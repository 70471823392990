import React, {useRef, useState, useLayoutEffect, useEffect} from "react"
import * as styles from "../styles/Drawing.module.scss"
import { useWindowSize } from "../hooks/useWindowSize"

const DrawingBoard = ({mode, dimension, toClear, background, pathCollection, oRect, originDrawpage, setIsIntro}) => {

    let isDrawing = false;
    let prevPoint = null;
	const [tools, setTools] = useState("pen");
	const canvasRef = useRef(null);
	const duplicateCanvasRef = useRef(null);
	
	var windowSize = useWindowSize();
	
	var width = dimension.width
	var height = dimension.height

	const coefMobile = mode === "game" ? 0.02 : 0.045
	const thicknessPen = !windowSize.desktop ? windowSize.width * coefMobile : (windowSize.width/windowSize.height > 1451/1060 ? windowSize.height * 0.02 : windowSize.width * 0.015)
	const thicknessPenEraser = windowSize.desktop ? thicknessPen * 12.5 : thicknessPen * 10

	var distancePerPoint = 3;
	var points;
	var timer = 0;
	const eraserPathRef = useRef();

	const doodleSvgHeight = 182.39;
	const doodleSvgWidth = 155.89;

	var arrayIncr = 0;

	var eraserCheck = false;

	const [penAnim, setPenAnim] = useState({
		visible: false,
		tool: null,
		x: 0,
		y: 0
	})

	
    function onDraw(ctx, point, prevPoint){
        if(tools === "pen"){
          drawLine(prevPoint, point, ctx, "source-over", "#000000", thicknessPen);
        }
        if(tools === "eraser"){
          drawLine(prevPoint, point, ctx,"destination-out" ,null, thicknessPenEraser);
        }
    }

    function drawLine(start, end, ctx, mode, color, width){
        
        start = start ?? end;
    
        ctx.globalCompositeOperation= mode;
    
        ctx.beginPath();
        ctx.lineWidth = width;
        ctx.strokeStyle = color;
        ctx.moveTo(start.x, start.y);
        ctx.lineTo(end.x, end.y);
        ctx.stroke();
    
        ctx.beginPath();
        ctx.arc(start.x, start.y, width/2 - 0.5, 0, 2 * Math.PI);
        ctx.fillStyle = color;
        ctx.fill();
    }

    function computePointInCanvas(clientX, clientY){
        const boundingRect = canvasRef.current.getBoundingClientRect();
        return {
            x : clientX - boundingRect.left,
            y : clientY - boundingRect.top,
        }
    }

    const mouseDown = (e) => {
		if (!originDrawpage) return
        const point = computePointInCanvas(e.clientX, e.clientY);
		handleDrawingStart(point);
    }

    const mouseMove = (e) => {
		if (!originDrawpage) return
		const point = computePointInCanvas(e.clientX, e.clientY);
		handleDrawingMove(point)
    }

    const touchStart = (e) =>{
		if (!originDrawpage) return
        const point = computePointInCanvas(e.changedTouches[0].pageX, e.changedTouches[0].pageY);
		handleDrawingStart(point);
    }

    const touchMove = (e) =>{
		if (!originDrawpage) return
		const point = computePointInCanvas(e.changedTouches[0].pageX, e.changedTouches[0].pageY);
		handleDrawingMove(point)
    }

    const handleDrawingEnd = () =>{
		if (!originDrawpage) return
        isDrawing = false;
        prevPoint = null;
    }

	const handleDrawingStart = (point) =>{
        const ctx = canvasRef.current.getContext("2d");
        onDraw(ctx, point, point);
		if(duplicateCanvasRef.current){
			const duplicateCtx = duplicateCanvasRef.current.getContext("2d");
			onDraw(duplicateCtx, point, point);
		}
        prevPoint = point;
        isDrawing = true;
	}

	const handleDrawingMove = (point) =>{
		if(isDrawing){
            const ctx = canvasRef.current.getContext("2d");
            if(onDraw) onDraw(ctx, point, prevPoint);
			if(duplicateCanvasRef.current){
				const duplicateCtx = duplicateCanvasRef.current.getContext("2d");
				if(onDraw) onDraw(duplicateCtx, point, prevPoint);
			}
            prevPoint = point;
        }
	}

	const mouseLeave = () =>{
		prevPoint = null;
	}

	const mouseEnter = (e) => {
		if(!e.buttons){
			isDrawing = false;
		}
	}

	function clearCanvas() {
		const ctx = canvasRef.current.getContext("2d");
		ctx.clearRect(0, 0, width, height);
	}

	// Fonctions nécessaires à l'animation de l'intro

	function startDrawingPath(anyPath, mode, lineWidth, toolName) {
		points = [];
		buildPath(anyPath);
		const ctx = canvasRef.current.getContext("2d");
		if(duplicateCanvasRef.current){
			var ctxDuplicate = duplicateCanvasRef.current.getContext("2d");
		}
		var i = 1;
		if(sessionStorage.getItem("noRedraw") !== "true"){
			timer = setInterval(()=>{
				if(i<points.length){
					for (let loopIndex = 0; loopIndex < (toolName === "eraser" ? 5 : 3) && i<points.length; loopIndex++ ){
						drawLine(points[i-1], points[i], ctx, mode, "#000000", lineWidth);
						if(duplicateCanvasRef.current){
							drawLine(points[i-1], points[i], ctxDuplicate, mode, "#000000", lineWidth);
						}
						setPenAnim({
							visible: true,
							x: toolName === "pen" ? points[i].x : points[i].x - 125/2,
							y: toolName === "pen" ? points[i].y - 125 : points[i].y - 125/2,
							tool: toolName,
						})
						if(i === points.length-1){
							drawLine(points[i], points[i], ctx, mode, "#000000", lineWidth);
							if(duplicateCanvasRef.current){
								drawLine(points[i], points[i], ctxDuplicate, mode, "#000000", lineWidth);
							}
						}
						i=i+1
					}			
				}
				else if(arrayIncr<pathCollection.length -1){
					clearInterval(timer);
					arrayIncr = arrayIncr+1;
					startDrawingPath(pathCollection[arrayIncr],"source-over", thicknessPen, "pen")
				}
				else{
					clearInterval(timer);
					if(originDrawpage && !eraserCheck){
						eraseCanvasAnim()
					}
					sessionStorage.setItem("noRedraw", true);
					
					setPenAnim({
						visible: false,
						x: 0,
						y: 0
					})
					
					setTimeout(() => {
						setIsIntro(false)
					}, 500)
				}
			}, (1000 / 30))
		}
		else{
			i = 1;
			while(i<points.length){
				drawLine(points[i-1], points[i], ctx, mode, "#000000", lineWidth);
				if(duplicateCanvasRef.current){
					drawLine(points[i-1], points[i], ctxDuplicate, mode, "#000000", lineWidth);
				}
				if(i === points.length-1){
					drawLine(points[i], points[i], ctx, mode, "#000000", lineWidth);
					if(duplicateCanvasRef.current){
						drawLine(points[i], points[i], ctxDuplicate, mode, "#000000", lineWidth);
					}
				}
				i=i+1;
			}
			if(arrayIncr<pathCollection.length -1){
				arrayIncr = arrayIncr+1;
				startDrawingPath(pathCollection[arrayIncr],"source-over", thicknessPen, "pen")
			}
			if(originDrawpage && !eraserCheck){
				eraseCanvasAnim()
			}
		}

	}

	function buildPath(anyPath) {
		var nextPoint = 0;
		var pathLength = anyPath.getTotalLength();

		while(nextPoint <= pathLength){		
			
			let pointToAdd
			let svgGrowthRatio

			if(duplicateCanvasRef.current){
				const svgHWRatio = doodleSvgHeight/doodleSvgWidth;
				const canvasRatio = dimension.height/dimension.width;
				if(svgHWRatio>canvasRatio){
					//le canevas est plus large que le svg
					 svgGrowthRatio = dimension.height/doodleSvgHeight;
				}
				else{
					//le canevas est plus haut que le svg
					 svgGrowthRatio = dimension.width/doodleSvgWidth;
				}

				const leftPadding = (dimension.width - (doodleSvgWidth*svgGrowthRatio))/2
				const topPadding = (dimension.height - (doodleSvgHeight*svgGrowthRatio))/2

				pointToAdd = {
					x: anyPath.getPointAtLength(nextPoint).x*svgGrowthRatio + leftPadding,
					y: anyPath.getPointAtLength(nextPoint).y*svgGrowthRatio + topPadding,
				}
			}
			else{
				svgGrowthRatio = oRect.width/doodleSvgWidth;
				pointToAdd = {
					x: anyPath.getPointAtLength(nextPoint).x*svgGrowthRatio + oRect.left,
					y: anyPath.getPointAtLength(nextPoint).y*svgGrowthRatio + oRect.top,
				}
			}
			points.push(pointToAdd);
			nextPoint = points.length * distancePerPoint;
		}
	}

	function eraseCanvasAnim(){
		eraserCheck = true;
		sessionStorage.setItem("noRedraw", false);
		startDrawingPath(eraserPathRef.current,"destination-out", thicknessPenEraser, "eraser");
	}

	// Clear le canvas au chargement du composant & quand la variable toClear est modifiée
	useEffect(() => {
		clearCanvas()
	},[toClear]);

	useLayoutEffect(() => {
		if(pathCollection && oRect){

			clearCanvas();
			let animationDelay

			if (sessionStorage.getItem("noRedraw") == "true"){
				startDrawingPath(pathCollection[0] ,"source-over", thicknessPen, "pen")
			} else {
				animationDelay = setTimeout(() => {
					startDrawingPath(pathCollection[0] ,"source-over", thicknessPen, "pen")
				}, 200)
			}
			
			return () => {
				clearInterval(timer)
				clearTimeout(animationDelay)
			}
		}
	},[dimension, pathCollection, oRect, timer])

	const stylePenAnim = {
		transform: `translate(${penAnim.x}px, ${penAnim.y}px)`,
		position: `absolute`,
		zIndex: `5`, 
	}

    return (
		
        <>
			{/* chemin de tracé la gomme */}
			<svg className={styles.eraserPath} id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" width="155.89" height="182.39" viewBox="0 0 155.89 182.39">
				<path ref={eraserPathRef} d="m125.17,165.57l19.86-61.46c-28.73,14.07-55.59,33.38-79.34,57.03,22.96-38.78,49.25-74.83,78.36-107.42-48.7,27.21-95.76,63.51-125.06,116.72,12.4-36.85,29.38-71.57,50.24-102.71,12.58-18.78,26.77-36.68,34.63-58.72C70.43,38.73,39.41,72.21,11.37,108.84c6.19-35.8,23.24-67.5,39.97-98.29" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.61"/>
			</svg>
			
			{/* svg du crayon */}
			{ (penAnim.visible && penAnim.tool === "pen") &&
				<svg style={stylePenAnim} className={styles.animPenStyle} xmlns="http://www.w3.org/2000/svg" width="125" height="125" viewBox="0 0 285.27 288.49"><title>Sans titre - 1</title>
					<path d="m239.71,43.61c4.76,4.6,9.61,7.32,10.82,6.07s-1.67-6-6.43-10.61c-4.76-4.6-9.61-7.32-10.82-6.07-1.21,1.25,1.67,6,6.43,10.61Z" fill="#161615"/>
					<path d="m277.81,75.1c8.48-11.52-19.95-40.03-25.87-45.74-5.91-5.72-35.36-33.16-46.59-24.3l-.06-.05-.73.76h0s0,0,0,0L59.82,155.51h0s-23.99,24.82-23.99,24.82c-1.37,1.42-3.47,3.76,1.82,12.1l-16.25,16.82,4.04,19-17.35,17.95,7,6.76-12.78,13.22,23.77,17.53,10.06-10.41,7.16,6.92,17.5-18.11,18.75,3.33,16.15-16.71c8.96,5.42,11.3,3.18,12.7,1.74l168.73-174.56h0s0,0,0,0l.73-.76-.06-.05ZM126.66,217.24c-4.19-1.78-15.86-9.75-31.9-25.26-16.07-15.54-24.43-26.93-26.32-31.03L204.49,20.2c5.73,12.75,21.09,28.1,25.26,32.12,4.17,4.03,20.03,18.86,32.97,24.16L126.66,217.24ZM245.01,36.53c15.47,14.95,22.78,26.92,24.19,31.72-4.85-1.24-17.06-8.14-32.52-23.09-15.47-14.95-22.78-26.92-24.18-31.72,4.85,1.24,17.05,8.14,32.52,23.09Zm-178.02,125.91h0Zm-23.94,103.67l-20.86-20.17,8.43-8.72c3.56,3.95,8.21,8.5,10.08,10.31,3.35,3.24,7.45,7.02,10.81,9.83l-8.46,8.75Zm33.01-11.42l-15.31-2.72c-4.6-3.6-8.98-7.48-13.12-11.61-4.29-4.01-8.34-8.27-12.13-12.76l-3.25-15.26,11.42-11.81c4.65,5.63,11.43,12.9,21.2,22.34,9.5,9.18,16.84,15.61,22.56,20.05l-11.37,11.76Zm27.03-13.09c-4.34-2.36-15.59-10.72-31.29-25.89s-24.43-26.14-26.93-30.39l16.34-16.9c3.72,6.43,11.46,16.08,26.61,30.73,15.15,14.65,25.06,22.05,31.62,25.56l-16.34,16.9Z" fill="#161615"/>
					<rect x="95.87" y="134.91" width="178.3" height="8.05" transform="translate(-43.46 175.4) rotate(-45.97)" fill="#161615"/>
				</svg>}

			{/* svg de l'éponge */}
			{(penAnim.visible && penAnim.tool === "eraser") &&	
				<svg style={stylePenAnim} className={styles.animPenStyle} id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" width="125" height="125" viewBox="0 0 278.27 306.49">
					<g id="Groupe_458" data-name="Groupe 458">
						<path id="Tracé_325" data-name="Tracé 325" d="m242.71,284.26c-25.49,19.93-79.97,39.14-122.47-15.23-8.21-10.5-11.78-22.16-15.23-33.43-3.48-11.37-7.08-23.12-15.55-33.95-10.16-13-19.86-18.61-30.11-24.54-9.83-5.68-20-11.56-29.79-24.08C-12.65,99.05,18.45,51.53,43.48,31.97S123.59-.85,162.39,48.78c8.96,11.47,12.77,23.76,16.46,35.65,3.7,11.93,7.19,23.2,15.57,33.93,8.64,11.06,19.63,17.66,30.26,24.06,10.13,6.1,20.61,12.4,28.4,22.36,21.97,28.1,22.89,53.67,19.79,70.16-3.82,19.52-14.52,37.02-30.16,49.31ZM48.98,39c-6.18,4.83-59.01,48.9-12.4,108.53,8.72,11.16,17.71,16.35,27.22,21.85,10.54,6.09,21.45,12.4,32.68,26.77,9.49,12.14,13.34,24.69,17.05,36.84,3.36,10.98,6.53,21.35,13.73,30.55,17.96,22.97,61.32,51.71,109.95,13.7,19.84-15.51,47.23-57.83,8.83-106.95-6.77-8.66-16.09-14.27-25.97-20.21-11.32-6.81-23.03-13.86-32.69-26.21-9.39-12.01-13.29-24.6-17.06-36.78-3.61-11.63-7.01-22.62-14.96-32.8C120.32,9.45,72.54,20.58,48.98,39Z" fill="#161615"/>
						<path id="Tracé_326" data-name="Tracé 326" d="m35.84,146.58l-7.03,5.5C-20.44,89.08,9.38,38.69,34.76,18.85,50.73,6.37,73.12.71,94.65,3.72c17.71,2.47,43.72,12.15,67.08,44.18l-7.22,5.26c-17.02-23.36-38.15-37.4-61.1-40.6-22.59-3.15-42.27,4.82-53.15,13.32-48.4,37.83-25.55,93.67-4.42,120.7Z" fill="#161615"/>
						<circle id="Ellipse_101" data-name="Ellipse 101" cx="45.64" cy="92.5" r="7.65" fill="#161615"/>
						<circle id="Ellipse_102" data-name="Ellipse 102" cx="40.75" cy="69.13" r="7.65" fill="#161615"/>
						<circle id="Ellipse_103" data-name="Ellipse 103" cx="66.03" cy="67.23" r="7.65" fill="#161615"/>
						<circle id="Ellipse_104" data-name="Ellipse 104" cx="87.12" cy="76.54" r="7.65" fill="#161615"/>
						<circle id="Ellipse_105" data-name="Ellipse 105" cx="144.34" cy="73.99" r="7.65" fill="#161615"/>
						<circle id="Ellipse_106" data-name="Ellipse 106" cx="110.77" cy="62.63" r="7.65" fill="#161615"/>
						<circle id="Ellipse_107" data-name="Ellipse 107" cx="135.15" cy="53.51" r="7.65" fill="#161615"/>
						<circle id="Ellipse_108" data-name="Ellipse 108" cx="112.73" cy="40.29" r="7.65" fill="#161615"/>
						<circle id="Ellipse_109" data-name="Ellipse 109" cx="81.36" cy="43.43" r="7.65" fill="#161615"/>
						<circle id="Ellipse_110" data-name="Ellipse 110" cx="107.4" cy="89.76" r="7.65" fill="#161615"/>
						<circle id="Ellipse_111" data-name="Ellipse 111" cx="94.89" cy="114.71" r="7.65" fill="#161615"/>
						<circle id="Ellipse_112" data-name="Ellipse 112" cx="117.94" cy="115.7" r="7.65" fill="#161615"/>
						<circle id="Ellipse_113" data-name="Ellipse 113" cx="62.71" cy="148.55" r="7.65" fill="#161615"/>
						<circle id="Ellipse_114" data-name="Ellipse 114" cx="67.67" cy="108.98" r="7.65" fill="#161615"/>
						<circle id="Ellipse_115" data-name="Ellipse 115" cx="40.43" cy="124.74" r="7.65" fill="#161615"/>
						<circle id="Ellipse_116" data-name="Ellipse 116" cx="97.52" cy="170.72" r="7.65" fill="#161615"/>
						<circle id="Ellipse_117" data-name="Ellipse 117" cx="119.26" cy="143.65" r="7.65" fill="#161615"/>
						<circle id="Ellipse_118" data-name="Ellipse 118" cx="86.94" cy="146.65" r="7.65" fill="#161615"/>
						<circle id="Ellipse_119" data-name="Ellipse 119" cx="140.21" cy="101.67" r="7.65" fill="#161615"/>
						<circle id="Ellipse_120" data-name="Ellipse 120" cx="160.26" cy="110.88" r="7.65" fill="#161615"/>
						<circle id="Ellipse_121" data-name="Ellipse 121" cx="193.53" cy="149.94" r="7.65" fill="#161615"/>
						<circle id="Ellipse_122" data-name="Ellipse 122" cx="170.93" cy="142.55" r="7.65" fill="#161615"/>
						<circle id="Ellipse_123" data-name="Ellipse 123" cx="126.97" cy="178.11" r="7.65" fill="#161615"/>
						<circle id="Ellipse_124" data-name="Ellipse 124" cx="144.86" cy="156.06" r="7.65" fill="#161615"/>
						<circle id="Ellipse_125" data-name="Ellipse 125" cx="132.32" cy="237.81" r="7.65" fill="#161615"/>
						<circle id="Ellipse_126" data-name="Ellipse 126" cx="176.69" cy="258.83" r="7.65" fill="#161615"/>
						<circle id="Ellipse_127" data-name="Ellipse 127" cx="155.91" cy="187.13" r="7.65" fill="#161615"/>
						<circle id="Ellipse_128" data-name="Ellipse 128" cx="148.92" cy="222.16" r="7.65" fill="#161615"/>
						<circle id="Ellipse_129" data-name="Ellipse 129" cx="129.59" cy="205.35" r="7.65" fill="#161615"/>
						<circle id="Ellipse_130" data-name="Ellipse 130" cx="208.78" cy="198.2" r="7.65" fill="#161615"/>
						<circle id="Ellipse_131" data-name="Ellipse 131" cx="187.46" cy="189.12" r="7.65" fill="#161615"/>
						<circle id="Ellipse_132" data-name="Ellipse 132" cx="204.64" cy="165.2" r="7.65" fill="#161615"/>
						<circle id="Ellipse_133" data-name="Ellipse 133" cx="239.75" cy="188.97" r="7.65" fill="#161615"/>
						<circle id="Ellipse_134" data-name="Ellipse 134" cx="248" cy="230.4" r="7.65" fill="#161615"/>
						<circle id="Ellipse_135" data-name="Ellipse 135" cx="226.34" cy="262.23" r="7.65" fill="#161615"/>
						<circle id="Ellipse_136" data-name="Ellipse 136" cx="230.33" cy="211.49" r="7.65" fill="#161615"/>
						<circle id="Ellipse_137" data-name="Ellipse 137" cx="195.84" cy="237.26" r="7.65" fill="#161615"/>
						<circle id="Ellipse_138" data-name="Ellipse 138" cx="194.21" cy="216.83" r="7.65" fill="#161615"/>
						<circle id="Ellipse_139" data-name="Ellipse 139" cx="221.33" cy="236.12" r="7.65" fill="#161615"/>
						<circle id="Ellipse_140" data-name="Ellipse 140" cx="201.22" cy="277.08" r="7.65" fill="#161615"/>
						<circle id="Ellipse_141" data-name="Ellipse 141" cx="147.26" cy="264.24" r="7.65" fill="#161615"/>
						<circle id="Ellipse_142" data-name="Ellipse 142" cx="162.84" cy="241.81" r="7.65" fill="#161615"/>
						<circle id="Ellipse_143" data-name="Ellipse 143" cx="142.73" cy="128.42" r="7.65" fill="#161615"/>
						<circle id="Ellipse_144" data-name="Ellipse 144" cx="172.62" cy="166.66" r="7.65" fill="#161615"/>
						<circle id="Ellipse_145" data-name="Ellipse 145" cx="172.75" cy="214.5" r="7.65" fill="#161615"/>
					</g>
				</svg>
			}
			

			<canvas
				width = {width}
				height = {height}
				className={`
					${styles.canvasClass} 
					${tools  === "pen" && originDrawpage ? styles.penCursor : "" }
					${tools  === "eraser" && originDrawpage ? styles.eraserCursor : "" }
					${!originDrawpage && penAnim.visible === false ? styles.hideCanvas: ""}
				`}
				ref={canvasRef}
				onMouseDown={ mouseDown }
				onMouseMove={ mouseMove }
				onMouseUp={ handleDrawingEnd }
				onMouseLeave={ mouseLeave }
				onMouseEnter={ mouseEnter }
				onTouchStart={ touchStart }
				onTouchMove={ touchMove }
				onTouchEnd={ handleDrawingEnd }

				mode={mode}
				background={background}
			/>
    	
			{ windowSize.width < 700 && mode === "drawApp" ?  
				<canvas
					width = {width}
					height = {height}
					className = {styles.duplicateCanvasClass}
					ref={duplicateCanvasRef}
				/> : ""
			}

			{mode === "drawApp" && originDrawpage ? 
				(<div className={styles.toolSelector}>
					<svg id = {styles.toolPen}
					data-name="Calque 1" 
					xmlns="http://www.w3.org/2000/svg" 
					viewBox="0 0 44.67 148.44"
					onClick= {() => {setTools("pen")}}
					className={`${styles.tools} ${tools  === "pen" ? styles.hiddenPen : "" }`}
					>
						<path d="M27.86,7.64c0-.74-2.28-1.34-5.09-1.34s-5.09,.6-5.09,1.34,2.28,1.34,5.09,1.34,5.09-.6,5.09-1.34Z" fill="#1d1d1b"/>
						<path d="M43.56,7.35C42.74,1.45,25.77,1.14,22.3,1.14S1.86,1.45,1.04,7.35h-.04V110.83c0,.82,.06,2.11,4.12,3.07v9.94l6.84,4.61v10.54h4.01v7.92l12.42-1.66v-6.26h4.11v-10.63l6.7-4.51v-9.88c4.33-.96,4.39-2.3,4.39-3.13V7.35h-.04Zm-3.96,88.02c-1.72,.7-7.65,1.74-17.3,1.74s-15.58-1.04-17.3-1.74V12.06c5.45,2.24,14.82,2.4,17.3,2.4s11.85-.16,17.3-2.4V95.37ZM22.3,5.14c9.47,0,15.29,1.59,16.96,2.66-1.67,1.07-7.5,2.66-16.96,2.66s-15.29-1.59-16.96-2.66c1.67-1.07,7.5-2.66,16.96-2.66Zm-6.34,129.85v-5.39c2.26,.16,5.11,.18,6.23,.18,2.02,0,4.45-.06,6.32-.2v5.4H15.96Zm19.25-13.28l-5.54,3.73c-.88,.14-3.5,.33-7.47,.33s-6.67-.18-7.51-.32l-5.56-3.75v-7.16c3.08,.35,7.32,.57,13.18,.57s9.85-.21,12.91-.54v7.13Zm-12.91-10.58c-9.41,0-15.34-.79-17.3-1.36v-10.2c.98,.26,2.1,.48,3.35,.67,3.74,.57,8.7,.88,13.95,.88s10.21-.31,13.95-.88c1.25-.19,2.37-.4,3.35-.67v10.2c-1.96,.57-7.89,1.36-17.3,1.36Z" fill="#1d1d1b"/>
						<rect x="31.5" y="16.36" width="4.4" height="75.66" fill="#1d1d1b"/>
					</svg>

					<svg id={styles.toolEraser}
					data-name="Calque 1" 
					xmlns="http://www.w3.org/2000/svg" 
					viewBox="0 0 74.26 147.48"
					onClick= {() => setTools("eraser")}
					className={`${styles.tools} ${tools  === "eraser" ? styles.hiddenEraser : "" }`}
					>
						<path d="m62.2,12.54c-6.31-6.61-14.98-10.44-24.1-10.65C24.35,1.89,1.09,10.99,1.09,45.1h0c0,.17,0,.34,0,.51.1,5.51,1.35,10.93,3.69,15.92,2.37,5.06,3.63,10.57,3.67,16.16-.23,5.35-1.49,10.61-3.69,15.5-2.17,4.8-3.41,9.97-3.66,15.23,0,29.44,23.36,37.3,37.16,37.3,8.48-.02,16.68-3.09,23.09-8.65,5.38-4.73,11.78-13.56,11.78-28.78-.28-5.18-1.53-10.25-3.67-14.98-2.28-5.05-3.59-10.5-3.85-16.04,0-5.81,1.79-10.51,3.68-15.49,2.3-5.14,3.6-10.68,3.84-16.3,0-.16-.01-.31-.01-.47h.02c.57-16.9-5.63-26.99-10.93-32.47h0Zm-24.1-6.84c8.09.24,15.76,3.65,21.36,9.49.99,1.03,1.91,2.13,2.75,3.29-7.42-6.94-17.21-9.88-24.64-9.88-6.65,0-15.56,1.89-22.91,7.26,5.13-5.96,12.65-10.15,23.44-10.16Zm27.63,54.75c-2.37,5.31-3.7,11.03-3.93,16.84,0,6.69,2.08,12.13,4.1,17.4,1.76,4.59,3.42,8.92,3.42,13.61,0,26.6-20.32,33.62-31.06,33.62-26.33,0-33.35-21.05-33.35-33.49.25-4.8,1.41-9.51,3.41-13.87,2.39-5.31,3.73-11.04,3.94-16.86-.05-6.08-1.4-12.07-3.95-17.58-2.14-4.54-3.3-9.48-3.4-14.5-.01-32.29,29.32-33.22,32.67-33.22h0c12.76,0,31.74,8.81,31.73,33.09,0,5.51-1.74,10.1-3.58,14.95h0Z" fill="#1d1d1b"/>
						<path d="m53.06,59.26c-1.8,0-3.26,1.46-3.26,3.26s1.46,3.26,3.26,3.26,3.26-1.46,3.26-3.26-1.46-3.26-3.26-3.26Z" fill="#1d1d1b"/>
						<path d="m34.01,26.59c1.8,0,3.26-1.46,3.26-3.26s-1.46-3.26-3.26-3.26-3.26,1.46-3.26,3.26,1.46,3.26,3.26,3.26Z" fill="#1d1d1b"/>
						<path d="m31.82,36.04c0,1.8,1.46,3.26,3.26,3.26s3.26-1.46,3.26-3.26-1.46-3.26-3.26-3.26-3.26,1.46-3.26,3.26Z" fill="#1d1d1b"/>
						<circle cx="56.68" cy="74.42" r="3.26" fill="#1d1d1b"/>
						<path d="m13.52,61.35c0,1.8,1.46,3.26,3.26,3.26s3.26-1.46,3.26-3.26-1.46-3.26-3.26-3.26-3.26,1.46-3.26,3.26Z" fill="#1d1d1b"/>
						<path d="m14.12,112.42c-1.8,0-3.26,1.46-3.26,3.26s1.46,3.26,3.26,3.26,3.26-1.46,3.26-3.26-1.46-3.26-3.26-3.26Z" fill="#1d1d1b"/>
						<path d="m24.11,49.86c0-1.8-1.46-3.26-3.26-3.26s-3.26,1.46-3.26,3.26,1.46,3.26,3.26,3.26,3.26-1.46,3.26-3.26Z" fill="#1d1d1b"/>
						<path d="m35.88,52.69c0,1.8,1.46,3.26,3.26,3.26s3.26-1.46,3.26-3.26-1.46-3.26-3.26-3.26-3.26,1.46-3.26,3.26Z" fill="#1d1d1b"/>
						<path d="m25.47,88.29c1.8,0,3.26-1.46,3.26-3.26s-1.46-3.26-3.26-3.26-3.26,1.46-3.26,3.26,1.46,3.26,3.26,3.26Z" fill="#1d1d1b"/>
						<path d="m20.41,78.5c1.8,0,3.26-1.46,3.26-3.26s-1.46-3.26-3.26-3.26-3.26,1.46-3.26,3.26,1.46,3.26,3.26,3.26Z" fill="#1d1d1b"/>
						<circle cx="43.8" cy="69.83" r="3.26" fill="#1d1d1b"/>
						<path d="m42.7,103.63c0-1.8-1.46-3.26-3.26-3.26s-3.26,1.46-3.26,3.26,1.46,3.26,3.26,3.26,3.26-1.46,3.26-3.26Z" fill="#1d1d1b"/>
						<circle cx="28.25" cy="111.58" r="3.26" fill="#1d1d1b"/>
						<path d="m43.08,127.77c-1.8,0-3.26,1.46-3.26,3.26s1.46,3.26,3.26,3.26,3.26-1.46,3.26-3.26-1.46-3.26-3.26-3.26Z" fill="#1d1d1b"/>
						<circle cx="19.34" cy="33.88" r="6.59" fill="#1d1d1b"/>
						<circle cx="57.03" cy="49.43" r="6.59" fill="#1d1d1b"/>
						<circle cx="50.82" cy="30.33" r="6.59" fill="#1d1d1b"/>
						<circle cx="21.09" cy="98.1" r="6.59" fill="#1d1d1b"/>
						<circle cx="53.16" cy="120.3" r="6.59" fill="#1d1d1b"/>
						<circle cx="58.12" cy="103.86" r="6.59" fill="#1d1d1b"/>
						<circle cx="29.86" cy="125.95" r="6.59" fill="#1d1d1b"/>
						<circle cx="47.33" cy="85.74" r="6.59" fill="#1d1d1b"/>
						<circle cx="30.5" cy="65.07" r="6.59" fill="#1d1d1b"/>
					</svg>
				</div>): ""
			}

        </>
    
    )

}

export default DrawingBoard