import React, {useState} from "react"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import * as styles from "../styles/rgpd.module.scss"
import { Link } from "gatsby"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const RgpdBanner = ({lang}) => {

  lang = lang.slice(0,2)
  const location = useLocation()

  const acceptCookies = () => {
    cookies.set('banner', "false", {maxAge: (24 * 365 * 3600), path: "/" } )
    cookies.set('gatsby-gdpr-google-analytics', "true", {path: "/"})
    initializeAndTrack(location)
    setBanner(false)
  }

  const refuseCookies = () => {
    cookies.set('banner', "false", {maxAge: (24 * 365 * 3600), path: "/" })
    setBanner(false)
  }
  
  const[banner, setBanner] = useState(() => {
    return cookies.get('banner') ? false
    : true
  })
  
  return (
    <>
      {banner && 
        <>
          <div className={`${styles.wrapper} rgpd-intro`}>
            <div className={styles.container}>
              <div className={styles.text}>
                {lang === "fr" &&
                  <>
                          Veuillez accepter ces cookies en guise de bienvenue :) <br /> Ces données sont mesurées de façon anonyme pour des analyses d'audience, pour plus d’informations veuillez consulter notre <Link to={`/${lang}/mentions-legales`}>politique de confidentialité.</Link>

                  </>
                }
                {lang === "en" &&
                  <>
                          Please accept these cookies as a welcome gift :) <br /> This data is anonymously measured for audience analysis purposes. For more information, please consult our <Link to={`/${lang}/legal-notice`}>privacy policy</Link>

                  </>
                }
              </div>
              <div class={styles.buttons}>
                <div className={styles.button} onClick={acceptCookies}>
                  {lang === "fr" ? "accepter" : "accept"}
                </div>
                
                <div className={styles.button} onClick={refuseCookies}>
                  {lang === "fr" ? "refuser" : "refuse"}
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default RgpdBanner
