exports.linkResolver = (doc) => {
        if (doc.type === 'homepage') {
          return `/${doc.lang.slice(0, 2)}/`
        }

        if (doc.type === 'contact') {
          return `/${doc.lang.slice(0, 2)}/${doc.uid}/`
        }

        if (doc.type === 'page') {
          return `/${doc.lang.slice(0, 2)}/${doc.uid}/`
        }
    
        if (doc.type === 'creation') {
          return `/${doc.lang.slice(0, 2)}/creations/${doc.uid}/`
        }

        if (doc.type === 'news') {
            return `/${doc.lang.slice(0, 2)}/news/${doc.uid}/`
          }
        
        if (doc.type === 'creationshub' || doc.type === 'newshub' ) {
          return `/${doc.lang.slice(0, 2)}/${doc.uid}/`
        }

        if (doc.type === 'drawing') {
          return `/${doc.lang.slice(0, 2)}/${doc.uid}/`
        }
    
        return `/${doc.lang.slice(0, 2)}/`
  }