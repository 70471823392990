import React, {useRef, useState, useLayoutEffect} from "react"
import DrawingBoard from "./DrawingBoard"
import { useBoundingRect } from "../hooks/useBoundingRect"
import * as styles from "../styles/background.module.scss"

const Background = ({color, type, originDrawpage, setIsIntro}) => {

  const drawingContainerRef = useRef();
  const boxSize = useBoundingRect(drawingContainerRef);
  const svgRef = useRef();
  const bgRef = useRef();
  const [pathCollection, setPathCollection] = useState(null);
  const [groupCollection, setGroupCollection] = useState(null);
  const [oRect, setORect] = useState(null);

  useLayoutEffect(() => {
    if(svgRef.current){
        setGroupCollection(svgRef.current.children)
      }
  }, [svgRef.current]);

  useLayoutEffect(()=>{
    if(groupCollection){
      if(sessionStorage.getItem("svg_index")==null){
        var index = Math.floor(Math.random()*groupCollection.length)
        setPathCollection(groupCollection[index].children);
        sessionStorage.setItem("svg_index", index)
      }
      else{
        setPathCollection(groupCollection[sessionStorage.getItem("svg_index")].children);
      }
    }
  }, [groupCollection])

  useLayoutEffect(()=>{
    if(bgRef.current){
      setORect({
        top: bgRef.current.children[2].getBoundingClientRect().top,
        left: bgRef.current.children[3].getBoundingClientRect().left,
        height: bgRef.current.children[3].getBoundingClientRect().top - bgRef.current.children[2].getBoundingClientRect().top,
        width: bgRef.current.children[2].getBoundingClientRect().left - bgRef.current.children[3].getBoundingClientRect().left,
      })
    }
  }, [bgRef])

  useLayoutEffect( () => {
    let timeoutId = null
    const resizeListener = () => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => setORect({
          top: bgRef.current.children[2].getBoundingClientRect().top,
          left: bgRef.current.children[3].getBoundingClientRect().left,
          height: bgRef.current.children[3].getBoundingClientRect().top - bgRef.current.children[2].getBoundingClientRect().top,
          width: bgRef.current.children[2].getBoundingClientRect().left - bgRef.current.children[3].getBoundingClientRect().left,
        }), 250)
    }
    window.addEventListener("resize", resizeListener)
    return () => window.removeEventListener("resize", resizeListener)
}, [])

  return (
    <div className={styles.container} type={type === "page" ? "scrollable" : "fixed"}>
      <svg ref={bgRef} x="0px" y="0px" viewBox="0 0 385.58 281.29" className={styles.logo} color={color}>
          <path d="m351.67,259.9h20.52v-105.65h13.38v-19.28h-47.29v19.28h13.39v105.65Zm-41.58,1.43c18.2,0,24.63-10.53,24.63-32.12v-22.84h-21.06v29.45c0,5.35-.54,8.74-3.75,8.74-1.43,0-2.32-.89-3.03-2.5-.71-1.96-.89-5.71-.89-44.62s.18-42.65.89-44.62c.71-1.61,1.61-2.5,3.39-2.5,2.86,0,3.75,2.68,3.75,9.82v28.38h21.24v-22.13c0-23.92-7.32-32.84-24.99-32.84-13.03,0-19.81,5.18-22.84,13.74-1.96,5.35-2.5,11.6-2.5,50.15s.54,44.8,2.5,50.15c3.03,8.57,9.82,13.74,22.67,13.74m-69.96-1.43h39.8v-19.27h-19.28v-33.55h17.85v-19.27h-17.85v-33.55h19.28v-19.28h-39.8v124.93Zm-33.19,1.43c14.63,0,23.74-8.57,23.74-22.67v-103.69h-20.7v105.48c0,2.5-.71,4.46-3.03,4.46-2.14,0-3.39-1.43-3.39-4.46v-54.08h-20.52v52.29c0,14.28,8.57,22.67,23.92,22.67m-132.15-72.28v-38.01h1.61c2.32,0,4.11.89,5.18,3.03,1.07,1.79,1.43,4.46,1.43,15.71s-.36,14.28-1.61,16.42c-1.25,1.96-2.68,2.86-5.35,2.86h-1.25Zm-20.52,70.85h20.52v-50.51h3.39l5.53,50.51h21.42l-7.32-54.43-9.82-2.85v-2.14c4.82-1.25,9.46-3.93,12.14-8.57,3.21-5.71,3.57-11.07,3.57-22.13,0-12.31-.18-17.85-3.57-23.74-4.28-7.5-11.96-11.07-21.95-11.07h-23.91v124.93Zm-33.73-65.68v-43.19h1.07c2.32,0,4.1.89,5.35,3.21,1.43,2.14,1.61,5.35,1.61,18.2s-.18,16.42-1.61,18.56c-1.25,2.5-3.21,3.21-5.35,3.21h-1.07ZM.01,259.9h20.52v-49.79h1.79c10.53,0,18.56-4.1,23.38-11.78,3.21-5.89,3.57-11.42,3.57-25.88s-.36-19.81-3.57-25.7c-4.82-8.03-13.21-11.78-23.38-11.78H.01v124.93Z"/>
          <path d="m307.06,126.52h18.03V15.69h3.03l.36,50.51,5.53,60.32h31.95V1.59h-18.03v110.83h-3.03l-.71-50.68-5.35-60.14h-31.77v124.93Zm-33.91,1.43c15.17,0,24.27-9.46,24.27-25.16V1.59h-20.35v103.69c0,3.75-1.25,5.89-3.93,5.89s-4.1-2.14-4.1-5.89V1.59h-20.52v101.19c0,15.88,8.92,25.16,24.63,25.16m-57.82,0c10.53,0,17.49-3.39,21.24-9.82,3.03-5.35,3.21-9.1,3.21-25.7,0-18.92-.18-22.49-3.21-27.49-1.79-3.39-4.82-6.25-9.1-8.21l-8.21-3.75c-2.14-1.25-3.75-2.5-4.46-4.11-1.07-1.61-1.61-4.28-1.61-16.42,0-8.39.18-12.49.89-13.92.54-.89,1.43-1.61,2.85-1.61,2.14,0,3.21,1.61,3.21,5.18v22.84h20.34v-19.45c0-17.31-7.32-25.34-23.56-25.34-10.89,0-18.03,3.75-21.42,10-3.21,5.17-3.21,9.46-3.21,25.52,0,17.85.18,22.13,3.21,27.31,1.79,3.21,5,6.25,9.64,8.21l7.85,3.21c1.96,1.07,3.39,2.32,4.28,3.93,1.07,1.96,1.61,3.39,1.61,16.42,0,9.1-.18,13.39-.89,14.63-.71,1.07-1.43,1.79-2.85,1.79-2.32,0-3.57-1.61-3.57-5.18v-23.2h-20.52v19.99c0,17.49,7.67,25.16,24.27,25.16m-88.34-1.43h39.8v-19.27h-19.28v-33.55h17.85v-19.27h-17.85V20.87h19.28V1.59h-39.8v124.93Zm-30.69,0h20.52V1.59h-20.52v52.83h-7.67V1.59h-20.52v124.93h20.52v-52.83h7.67v52.83Zm-67.99,0h20.52V20.87h13.39V1.59H14.92v19.27h13.39v105.65Z"/>
          <circle cx="230.68" cy="99.07" r=".08" />
          <circle cx="74.79" cy="281.29" r=".08"/>
      </svg>

      <svg style={{display: `none`, visibilty: `hidden`}} ref={svgRef}  id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" width="155.89" height="182.39" viewBox="0 0 155.89 182.39">
          <g>
            {/* circle */}
            <path d="m65.48,49.5S15.84,88.1,15.84,137.36c0,20.27,13.21,36.03,33.64,36.03,39.86,0,82.56-58.27,82.56-109.21,0-27.87-11.88-43.63-35.34-43.63-38.09,0-75.49,57.42-75.49,57.42" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.38"/>
          </g>
          <g>
            {/* flower */}
            <path d="m62.08,64.27c.51,3.78,1.54,7.58,3.93,10.91,2.4,3.33,6.31,6.15,11.07,7.1,4.76.95,10.32-.28,13.02-3.42,2.62-3.05,2.17-7.14.69-10.65-1.3-3.1-3.32-6.02-5.93-8.59,3.34,5.18,11.91,7.1,19.3,6.47,6.64-.57,12.99-2.78,17.94-6.22,4.35-3.03,7.72-7.67,5.91-12.02-1.58-3.78-6.52-6.09-11.34-7.48-9.55-2.75-20.14-3.23-29.96-1.34,5.23-2.28,9.49-5.86,11.99-10.08,2.04-3.45,2.89-7.47,1.32-11.09-1.21-2.79-3.76-5.14-6.74-6.98-9.2-5.67-23.2-6.3-32.68-1.08-9.47,5.22-13.15,16.03-7.72,24.09-6.56-1.62-13.46-2.37-20.32-2.21-6.59.16-13.83,1.51-17.43,5.75-3.86,4.56-1.79,11.19,3.49,14.89,5.28,3.7,12.94,4.81,19.97,4.14-5.24,2.07-9.31,5.82-11.05,10.17-1.29,3.24-1,7.32,2.52,9.39,2.89,1.7,6.94,1.47,10.42.76,6.17-1.27,11.87-3.83,16.33-7.35,0,0-20.29,55.45-.27,106.93-3.59-56.79,50.3-82.73,50.3-82.73,0,0,14.37,64.15-43.12,81.75" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.38"/>
            <path d="m70.11,59.55c2.56-.37,7.12-2,8.02-4.4,1.13-4.92-8.38-8.99-17.56-9.06-9.19-.07-16.08,3.24-15.39,7.41.68,4.13,7.88,7.03,16.56,7.5" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.38"/>
          </g>
          <g>
            {/* swirl */}
            <path d="m117.98,49.12c1.54,4.59,2.04,9.92-1.54,13.69-4.7,4.96-13.91,4.9-21.53,3.77-20.61-3.05-40.18-10.55-56.13-21.5-4-2.74-7.89-5.84-9.77-9.78-.5-1.06-.86-2.22-.51-3.32.85-2.71,5.16-3.48,8.62-3.38,13.87.42,26.47,6.45,37.59,13.14,17.93,10.78,34.65,25.02,38.08,42.83.83,4.3.73,9.03-2.38,12.59-5.38,6.17-16.75,6.01-25.84,4.2-18.57-3.71-35.5-11.49-51.24-20.24-5.06-2.81-10.15-5.84-13.39-10.05-.52-.68-1-1.43-1-2.22,0-1.98,2.75-3.14,5.15-3.57,10.79-1.95,21.91,1.63,31.56,5.99,14.98,6.76,28.62,15.67,38.76,26.82,10.14,11.15,16.65,24.63,17.09,38.45.09,2.82-.13,5.81-2.03,8.18-2.29,2.86-6.7,4.28-10.93,4.34-4.23.06-8.34-1.04-12.28-2.29-18.81-5.95-35.58-15.39-51.54-25.37-6.9-4.31-13.83-8.88-18.27-14.94-.87-1.18-1.65-2.52-1.39-3.88.57-2.95,5.34-3.86,9.03-3.53,7.86.71,15.03,3.88,21.75,7.25,31.69,15.86,60,38.95,68.98,68.11" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.38"/>
          </g>
          <g>
            {/* arrow */}
            <path d="m81.14,33.48c8.32,7.91,19.75,11.59,30.85,13.85-10.94-1.82-22.33,1.86-29.57,9.55" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.38"/>
            <path d="m83.35,45.42c-32.51-5.22-61.58,29.21-64.01,63.31-2.43,34.1,18.19,60.38,46.06,58.7,32.86-1.98,59.12-28.89,57.47-69.11" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.38"/>
          </g>
          <g>
            {/* star */}
            <path d="m103.02,19.22h0c-2.39,22.18-11.77,71.03,30.77,58.38,0,0-49.21,24.41-17.26,68.9,0,0-39.19-41.76-52.29,27.54-3.64-57.79-18.97-50.33-47.95-34.55,32.9-34.11,19.48-53.13-7.5-70.63,0,0,43.56,21.34,44.54-39.24,12.87,41.72,30.66,21.81,41.82,2.92" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.38"/>
          </g>
          <g>
            {/* cloud */}
            <path d="m87.01,71.73c1.56-6.9,2.62-14.16,2.08-21.36-.54-7.2-2.85-14.37-7.01-18.74-4.16-4.37-10.29-5.38-14.66-1.41-4.24,3.85-6.02,11.28-6.49,18.27-.41,6.17-.02,12.44,1.15,18.42-.46-10.97-7.83-19.23-15.44-22.31-6.83-2.77-14.28-2.53-21.01.69-5.92,2.83-11.77,9.06-12.36,17.71-.51,7.52,3.09,14.38,7.06,19.56,7.88,10.26,18.03,17.11,28.72,19.39-6.39,1.01-12.52,4.87-17.28,10.86-3.88,4.88-6.91,11.44-7.35,18.68-.33,5.57.89,11.16,2.81,16.07,5.95,15.17,19.36,24.24,31.52,20.49,12.15-3.75,21.67-20.61,20.73-37.82,5.56,6.57,11.94,11.82,18.77,15.43,6.56,3.47,14.42,5.23,20.26-.17,6.29-5.8,7.86-18.59,4.69-28.09-3.17-9.5-10.09-15.8-17.36-18.62,6.28-.65,12.32-4.9,16.4-11.54,3.04-4.94,4.97-12.26,2.64-17.89-1.91-4.63-6.02-6.53-9.83-7.26-6.76-1.28-13.76-.03-20.05,3.59" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.38"/>
          </g>
          <g>
            {/* heart */}
            <path d="m85.69,67.81s-2.93-36.8-37.49-44.14c-18.05-3.84-34.96,10.92-39.87,34.01-9.72,45.75,32.34,83.09,51.31,112.39,23.54-18.04,74.53-40.86,83.42-82.71,6.35-29.89-5.15-51.1-26.37-55.61-8.49-1.8-17.72,1.3-25.74,5.68" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.38"/>
          </g>
          <g>
            {/* handwritten o */}
            <path d="m52.41,62.14s-26.33,31.13-26.33,64.14c0,24.27,11.24,40.77,28.09,40.77,36.52,0,60.04-48.54,60.04-87.37,0-27.18-12.28-51.61-37.09-51.61s-49.17,18.57-49.17,40.41,14.78,36.44,38.98,36.44c30.02,0,48.88-12.4,65.85-40.84" fill="none" stroke="#1d1d1b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="7.61"/>
          </g>
      </svg>

      < div ref={drawingContainerRef} className={styles.drawingApp}>
        <DrawingBoard 
          mode={"drawApp"}
          dimension={boxSize}
          pathCollection = {pathCollection}
          background={color}
          oRect = {oRect}
          originDrawpage = {originDrawpage}
          setIsIntro={setIsIntro}
        />
      </div>

    </div>
  )
}

export default Background
